<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="vouchers"
        :options.sync="pagination"
        :server-items-length="totalVouchers"
        :loading="loading"
        :footer-props="{
        'showFirstLastPage':true,
        'items-per-page-options': [10, 20, 50, 100]
      }"
        class="elevation-1 class-on-data-table"
    >
      <template v-slot:item.expired="{item}">
        {{ item.expired === true ? "Yes" : "No" }}
      </template>
      <template v-slot:item.edit="{ item }">
        <v-avatar color="teal" size="26" rounded>
          <router-link
              :to="{
              name: 'VoucherEdit',
              params: { voucherId: item.id }
            }"
          >
            <v-icon dark>mdi-pencil</v-icon>
          </router-link>
        </v-avatar>
      </template>
      <template v-slot:item.delete="{ item }">
        <v-avatar color="red darken-4" size="26" rounded>
          <v-icon dark @click="delRecord(item)">mdi-delete</v-icon>
        </v-avatar>
      </template>
      <template v-slot:item.validUntil="{ item }">
        {{ parseDate(item.validUntil) }}
      </template>
      <template v-slot:item.users="{ item }">
        {{ item.users.length == 1 ? (item.users[0].userName + " | " + (item.users[0].claimed === true ? 'Claimed' : 'Not claimed') + " | " + (item.users[0].used === true ? 'Used' : 'Not used')) : "" }}
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-icon v-if="item.users.length > 1" small class="mr-2" v-on="on"
            >mdi-dots-horizontal
            </v-icon>
          </template>
          <v-list dense style="max-height: 500px" class="overflow-y-auto">
            <v-list-item v-for="(item, i) in item.users" :key="i">
              <v-list-item-title>{{ item.userName }} | {{item.claimed === true ? 'Claimed' : 'Not claimed'}} | {{item.used === true ? 'Used' : 'Not used'}}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.shops="{ item }">
        {{ item.shops.length == 1 ? item.shops[0].shopName : "" }}
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-icon v-if="item.shops.length > 1" small class="mr-2" v-on="on"
            >mdi-dots-horizontal
            </v-icon>
          </template>
          <v-list dense style="max-height: 500px" class="overflow-y-auto">
            <v-list-item v-for="(item, i) in item.shops" :key="i">
              <v-list-item-title>{{ item.shopName }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.id="{ item }">
        <v-menu :close-on-content-click=false bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-icon small class="mr-2" v-on="on"
            >mdi-focus-field-horizontal
            </v-icon>
          </template>
          <v-list dense style="max-height: 500px" class="overflow-y-auto">
            <v-list-item >
              <v-list-item-title>{{ item.id }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:body.prepend>
        <tr>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-text-field v-model="userName" type="text"
                          @keyup.enter="page = 0;retrieveVouchers();"></v-text-field>
          </td>
          <td>
            <v-text-field v-model="shopName" type="text"
                          @keyup.enter="page = 1;retrieveVouchers();"></v-text-field>
          </td>
          <td>
            <v-avatar color="indigo" size="30">
              <v-icon
                  dark
                  @click="
                      page = 1;
                      retrieveVouchers();
                    "
              >mdi-magnify
              </v-icon>
            </v-avatar>
          </td>
          <td>
            <v-avatar color="grey" size="30">
              <v-icon
                  dark
                  @click="
                  page = 1;
                  clearSearch();
                "
              >mdi-close
              </v-icon>
            </v-avatar>
          </td>
          <td>
            <router-link :to="{ name: 'VoucherCreate' }">
              <v-avatar color="teal" size="26" rounded>
                <v-icon dark>mdi-plus</v-icon>
              </v-avatar>
            </router-link>
          </td>
        </tr>
      </template>
    </v-data-table>
    <ConfirmDialog ref="confirm"/>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import moment from "moment";
import VouchersApiService from "@/core/services/api.service.vouchers";
import ConfirmDialog from "@/view/pages/ConfirmDialog";

export default {
  name: "vouchers",
  computed: {
    name() {
      return this.$i18n.t("menu.vouchers")
    },
    yesNoAllList() {
      return [this.$i18n.t ( "common.all" ) , this.$i18n.t ( "common.no" ) , this.$i18n.t ( "common.yes" )]
    },
    headers() {
      return [
        {text: 'Id', align: "start", sortable: false, value: "id"},
        {text: this.$i18n.t('common.description'), value: "description", sortable: false},
        {text: this.$i18n.t('common.price'), value: "price", sortable: false},
        {text: this.$i18n.t('common.code'), value: "codeForUser", sortable: false},
        {text: this.$i18n.t('common.validUntil'), value: "validUntil", sortable: false},
        {text: this.$i18n.t('common.expired'), value: "expired", sortable: false},
        {text: this.$i18n.t('common.users'), value: "users", sortable: false},
        {text: this.$i18n.t('common.shops'), value: "shops", sortable: false},
        {text: "", value: "empty", width: "5%", sortable: false},
        {text: "", value: "edit", width: "5%", sortable: false},
        {text: "", value: "delete", width: "5%", sortable: false}
      ]
    }
  },
  data() {
    return {
      vouchersApiInitiated: false,
      used: null,
      claimed: null,
      userName: null,
      shopName: null,
      totalVouchers: 0,
      vouchers: [],
      loading: true,
      pagination: {},
      errors: []
    };
  },
  watch: {
    pagination: {
      handler() {
        if (this.vouchersApiInitiated) this.retrieveVouchers();
      },
      deep: true
    }
  },
  async created() {
    this.loading = true;
    this.$log.debug("await init vouchers service - start");
    await VouchersApiService.init();
    this.$log.debug("await init vouchers service - end");
    this.vouchersApiInitiated = true;
    this.retrieveVouchers();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.name}]);
  },
  components: {
    ConfirmDialog
  },
  methods: {
    async delRecord(item) {
      if (
          await this.$refs.confirm.open(
              "Confirm",
              "Are you sure you want to delete voucher with id: " + item.id + " ?"
          )
      ) {
        this.deleteVoucher(item);
      }
    },
    deleteVoucher(item) {
      this.loading = true;
      if (item.id != null) {
        VouchersApiService.delete(`/${item.id}`)
            .then(response => {
              this.$log.debug("Deleted voucher: ", item.id);
              this.$log.debug("Response: ", response);
            })
            .catch(error => {
              this.$log.error("Error: ", error);
              this.errored = true;
            })
            .finally(() => (this.retrieveVouchers(), this.loading = false));
      }
    },
    parseDate(date) {
      if (date == null) return "";
      return moment
          .utc(date)
          .local()
          .format("yyyy-MM-DD HH:mm:ss");
    },
    getRequestParams(
        used,
        claimed,
        userName,
        shopName
    ) {
      let params = {};
      if (used &&( used !== this.$i18n.t ( "common.all" ))) {
        if (used === this.$i18n.t ( "common.yes" )) {
          params["Used"] = true;
        } else if (used === this.$i18n.t ( "common.no" )) {
          params["Used"] = false;
        }
      }
      if (claimed &&( claimed !== this.$i18n.t ( "common.all" ))) {
        if (claimed === this.$i18n.t ( "common.yes" )) {
          params["Claimed"] = true;
        } else if (claimed === this.$i18n.t ( "common.no" )) {
          params["Claimed"] = false;
        }
      }
      if (userName) {
        params["Username"] = userName;
      }

      if (shopName) {
        params["Shopname"] = shopName;
      }
      let sort;
      let sortBy = this.pagination.sortBy;
      let sortDesc = this.pagination.sortDesc;
      if (sortBy.length === 1 && sortDesc.length === 1) {
        sort = sortBy[0];
        if (sortDesc[0] === true) sort += ",desc";
      }
      params["Sort"] = sort;
      params["PageNumber"] = this.pagination.page - 1;
      params["PageSize"] = this.pagination.itemsPerPage;

      return params;
    },
    clearSearch() {
      this.used = null;
      this.claimed = null;
      this.userName = null;
      this.shopName = null;
    },
    retrieveVouchers() {
      const params = this.getRequestParams(
          this.used,
          this.claimed,
          this.userName,
          this.shopName
      );

      return new Promise(resolve => {
        this.loading = true;
        VouchersApiService.query("",
            params
        ).then(response => {
          // JSON responses are automatically parsed.
          this.$log.debug("Vouchers: ", response.data.result);
          this.$log.debug("response.headers['x-pagination']: ", response.headers['x-pagination']);
          this.vouchers = response.data.result;
          if (response.headers['x-pagination'] != null) {
            let xPaginationHeaders = JSON.parse(response.headers['x-pagination']);
            this.totalVouchers = xPaginationHeaders.totalCount;
            this.totalPages = xPaginationHeaders.totalPages;
          } else {
            this.totalVouchers = undefined;
            this.totalPages = undefined;
          }
          this.loading = false;
          let title = this.totalVouchers;
          this.$store.dispatch(SET_BREADCRUMB, [{title: `${this.name}: ` + title}]);
          resolve();
        });
      });
    }
  }
};
</script>
<style>
.old-price {
  text-decoration: line-through;
}

.v-data-table.v-data-table.v-data-table >>> td {
  font-size: 16px;
}

.v-icon.v-icon {
  font-size: 24px !important;
}

tbody tr:nth-of-type(even) {
  background-color: rgba(236, 237, 237);
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250, 250, 250);
}

.old-price {
  text-decoration: line-through;
}

.v-data-table-header th {
  white-space: normal;
}

.table-footer-prepend {
  margin-top: -58px;
  height: 58px;
}
</style>
